import React, { useEffect } from 'react';
// import '@src/index.css';
import './styles.css'
import '../home.css'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SFZ from '@src/asset/sfz_remove.png'
import { useIntl, FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import "@glidejs/glide/dist/css/glide.core.min.css"
import Glide from '@glidejs/glide'
import { Link, navigate } from 'gatsby'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const paper_sm = 4
const paper_xs = 12
const elv = 5

const IndexProduct = ({ slides, imageUrlCollection, language }) => {

  const intl = useIntl()
  const productInfo = {}

  slides.map(slide => {
    productInfo[slide] = require(`@src/solutions/${slide}/${language}.json`)
  })

  console.log("imageUrlCollection : ", imageUrlCollection)

  console.log("slides : ", slides)

  useEffect(() => {
    new Glide('#index-product-glide', {
      type: 'carousel',
      perView:  5,
      // autoplay : 2000,
      gap : 50,
      // focusAt: 'center',
      // startAt : 1,
      breakpoints: {

        1920 : {

          perView : 4,
        },

        1700 : {

          perView : 4,
        },

        1440 : {

          perView : 3,
        },
        1200 : {
          perView : 2,
          // gap : 200,
        },
        1024: {
          perView: 1,
          gap : 25
        },
        600: {
          perView: 1,
          gap : 25
        }
      },
      peek: {
        before : 50,
        after: 50
      }
    }).mount()
  }, [])


  const onClickSlides = (url) => { console.log(url) ; navigate(`/${language}/solutions/${url}/`) }

  return <React.Fragment>
   <div class = "index-block">
    {/* <div class = "index-block2"> */}
      <h2 class = {`index-block-title`}> {intl.formatMessage({ id : "index-product-title"})} </h2>
      <div class = 'index-product-glide-container'>
        <div id = 'index-product-glide' class = "glide">
          <div data-glide-el = "track" class = "glide__track">
            <div class = "glide__slides">
              {
                // slides.map((product, idx) => {
                //   console.log("home-product : ", product)
                //   return <div class = "glide__slide index-product-glide" onClick = { () => onClickSlides(product) } >
                //     <img src = {imageUrlCollection[product][0]} class = {`index-product-glide-img`}/>
                //     {/* <span class = {`index-product-glide-category`} >{productInfo[product].category}</span> */}
                //     <span class = {`index-product-glide-title`} >{productInfo[product].name}</span>
                //   </div>
                // })
                slides.map((product, idx) => {
                  console.log("home-product : ", product)
                  console.log("home-product imagecoll : ", imageUrlCollection)
                  return <div class = "glide__slide index-product-glide" onClick = { () => onClickSlides(product) } >
                    <Card elevation = {5} classes = {{ root : "index-solutions-card" }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt = { productInfo[product].name }
                        height = "200"
                        image = { imageUrlCollection[product][productInfo[product]["index-slide-image"]] }
                        title = { productInfo[product].name }
                      />
                      <CardContent>
                        <Typography align = "left" gutterBottom variant = "h6" component = "h3">
                          {productInfo[product].name}
                        </Typography>
                        <Typography align = "left" classes = {{root : 'index-solutions-content'}} variant = "body1" color = "textSecondary" component = "h4">
                          {productInfo[product].slogan}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      {/* <Button size="small" color="primary">
                        Share
                      </Button> */}
                      <Button onClick = { () => onClickSlides(product) } size = "small" color = "primary">
                        { intl.formatMessage({ id : "index-learnmore"})}
                      </Button>
                    </CardActions>
                  </Card>
                    {/* <img src = {imageUrlCollection[product][0]} class = {`index-product-glide-img`}/> */}
                    {/* <span class = {`index-product-glide-category`} >{productInfo[product].category}</span> */}
                    {/* <span class = {`index-product-glide-title`} >{productInfo[product].name}</span> */}
                  </div>
                })
              }
            </div>
          </div>
          {/* <div class="glide__arrows" data-glide-el="controls">
            <button class="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
            <button class="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
          </div> */}

          {/* <div class="glide__bullets" data-glide-el="controls[nav]">
            <button class="glide__bullet" data-glide-dir="=0"></button>
            <button class="glide__bullet" data-glide-dir="=1"></button>
            <button class="glide__bullet" data-glide-dir="=2"></button>
          </div> */}
          </div>
          </div>
          {/* </div> */}
        {/* <Grid container alignItem = "center" justify = "center" spacing = {5} classes = {{ root : "index-product-paper-container" }}>

  
        <Grid item xs = {paper_xs} sm = {paper_sm} classes = {{ root : "index-product-grid" }}>

        <Paper elevation = {elv} classes = {{ root : "index-product-paper" }}>
          <img src = {SFZ} class = "index-product-paper-img"/>
          <span class = {`index-product-paper-title`}>SFZ-120</span>
        </Paper>
        </Grid>
        

        <Grid item xs = {paper_xs} sm = {paper_sm} classes = {{ root : "index-product-grid" }}>
        <Paper elevation = {elv} classes = {{ root : "index-product-paper" }}>
        <img src = {SFZ} class = "index-product-paper-img"/>
        <span class = "index-product-paper-title">CP-120</span>
        </Paper>

        </Grid>
        <Grid item xs = {paper_xs} sm = {paper_sm} classes = {{ root : "index-product-grid" }}>
        <Paper elevation = {elv} classes = {{ root : "index-product-paper" }}>
        <img src = {SFZ} class = "index-product-paper-img"/>
        <span class = "index-product-paper-title">APS</span>
        </Paper>

        </Grid>



      </Grid> */}

    </div>
  </React.Fragment>

}



export default IndexProduct