import React from 'react'
import '@src/styles/common.css'
import './home.css'
import { Top, Footer, Meta, Advice } from '@src/share-component'
import { Solutions, Product } from './components'
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Loading from '@src/share-component/loading/loading';
import { Helmet } from "react-helmet"
import { useIntl } from "gatsby-plugin-react-intl"
import AboutImage from '@src/asset/about_4.jpg'
import Construct from '@src/asset/construct1.jpg'
import { navigate } from 'gatsby'

const Home = (data) => {

  const language = data.pageContext.intl.language
  const relativeDir = data.pageContext.rDir
  const imageUrlCollection = data.pageContext.imageUrlCollection
  const json = require(`@src/${relativeDir}/${language}.json`)
  const intl = useIntl()

  // console.log("home lanugage : ", navigator.language)
  // console.log("home href : ", window.location.href)

  // const slugs = window.location.href.split('/')

  // if (slugs[-1] = "")
  //   slugs.pop()

  // if (navigator.language.includes('zh')){
  //   // if (slugs[-1])
  //   // if (window.location.href != )      navigate('/zh')
  //   // slugs.reduce(s => {
  //   //   if (s == "zh")
  //   // })
  // }
  
  return <React.Fragment>
    <Meta
      title = { intl.formatMessage({ id : "index-meta-title" }) }
      keywords = { intl.formatMessage({ id : "index-meta-keywords" }) }
      description = { intl.formatMessage({ id : "index-meta-description" }) }
      slug = "/"
    />
    <Loading/>
    <div class = {`page`}>
    {/* <Top/> */}
    <Top useColorBar = {true}/>
    <Solutions/>
    <Product slides = {json["index-slides"]} imageUrlCollection = {imageUrlCollection} language = {language}/>
    <div id = 'about' class = 'index-block'>
      <img id = 'index-about-bg' src = {AboutImage} class = "index-block-bg" />
      <div class = 'index-block2'>
      <Typography variant = "h4" component = "h2" classes = {{ root : "index-block2-title" }}>
        { intl.formatMessage({ id : "index-about-title"}) }
      </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "index-block2-content" }}>
        { intl.formatMessage({ id : "index-about-content"}) }
      </Typography>
      </div>
    </div>

    <div id = 'index-why' class = 'index-block'>
      <img id = 'index-why-bg' src = {Construct} class = "index-block-bg" />
      <div class = 'index-block2-right'>
      <Typography variant = "h4" component = "h2" classes = {{ root : "index-block2-title" }}>
      { intl.formatMessage({ id : "index-why-title"}) }
      </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "index-block2-content" }}>
      { intl.formatMessage({ id : "index-why-content"}) }
      </Typography>
      </div>
    </div>

    <Advice/>
    <div class = 'b-link'>
      <a href="http://www.cotid.org/">submit your Url to cotid.org</a> tto improve marketing This site is listed under <a href="http://www.botid.org/Business/Manufacturing/">Manufacturing Directory </a>
      Submit your site to the best <a href="http://www.hotid.org/">one of the best web directories</a> for free! This site is listed under <a href="http://www.hotid.org/Business/Manufacturing/">Manufacturing Directory </a>
    </div>
    <Footer/>
    </div>
  </React.Fragment>
}

export default Home